import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, tap, map } from 'rxjs/operators';
import * as RouterActions from '../actions/entities.action-types';
import { Store } from '@ngrx/store';
import { EntityType } from 'src/app/shared/models/entity-type.enum';
import { currentEntityLoaded } from '../actions/entities.action-types';
import { HierarchyService } from 'src/app/shared/services/rest-services/hierarchy.service';
import { LoggerService, Logger } from 'src/app/shared/services/logger.service';
import { loadHierarchy } from '../actions/hierarchy.actions';
import { SingleDeviceType } from 'src/app/shared/models/single-device.model';

@Injectable()
export class HierarchyEffects {
    logger: Logger;
    constructor(private actions$: Actions,
        private store: Store<any>,
        private hierarchy$: HierarchyService,
        private loggerFactory: LoggerService) {
        this.logger = this.loggerFactory.getLogger("HierarchyEffects")

    }
    /**
     * Load the hierarchy once there was a navigation
     */
    loadHierarchy$ = createEffect(() => this.actions$.pipe(
        // loadHierarchy$ = this.actions$.pipe(
        ofType(RouterActions.currentEntityNavigated),
        tap(() => {
            this.logger.debug('effect currentEntityNavigated called')
            //console.log('effect router navigation called')
        }),
        mergeMap((action) => {
            // fetch the hierarchy here and dispatch an action about it
            return this.hierarchy$.fetchHierarchy(action.entityId, action.entityType).pipe(
                map(hierarchy => {
                    this.logger.debug('fetched hierarchy %o entityType %s', hierarchy, action.entityType)
                    let entity = hierarchy.slice(0).reverse().find(step => step.type == action.entityType || !!SingleDeviceType[step.type])
                    // copy the object to avoid changing the hierarchy exact device type
                    // entity = Object.assign({}, entity)
                    // Note that in case that we get one of the device types the current entity type will be device
                    if (!!SingleDeviceType[entity.type]) {
                        entity.type = EntityType.DEVICE;
                    }
                    this.store.dispatch(currentEntityLoaded({ entity }))
                    //this.store.dispatch(loadHierarchy(hierarchy))
                    return loadHierarchy({ hierarchy })
                })
            )

            //return this.store.select(selectEntityType)
        })
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        // concatMap(() => EMPTY)
    ));
}
